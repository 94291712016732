/* eslint-disable jsx-a11y/no-redundant-roles */
import { Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuAlt2Icon } from "@heroicons/react/outline";
import { Auth } from "aws-amplify";
import classNames from "classnames";
import { FC, Fragment } from "react";

const MainLayout: FC = ({ children }) => {
  return (
    <div className="flex flex-col md:pl-64">
      <div className="sticky top-0 z-10 flex flex-shrink-0 h-16 bg-white shadow">
        <button
          type="button"
          className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-teal md:hidden"
          // onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuAlt2Icon className="w-6 h-6" aria-hidden="true" />
        </button>
        <div className="flex justify-between flex-1 px-4">
          <div className="flex flex-1">
            {/* <form className="flex w-full md:ml-0" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                  <SearchIcon className="w-5 h-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  className="block w-full h-full py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 border-transparent focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                  placeholder="Search"
                  type="search"
                  name="search"
                />
              </div>
            </form> */}
          </div>
          <div className="flex items-center ml-4 md:ml-6">
            <button
              type="button"
              className="p-1 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
            >
              <span className="sr-only">View notifications</span>
              <BellIcon className="w-6 h-6" aria-hidden="true" />
            </button>

            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex items-center max-w-xs text-sm bg-white rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal">
                  <span className="sr-only">Open user menu</span>
                  <div className="relative bg-primary-blue-dark rounded-full flex items-center justify-center w-8 h-8 min-w-[32px]">
                    <img
                      className="object-contain w-6"
                      src="/logo.png"
                      alt=""
                    />
                  </div>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute border right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {/* {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a
                          href={item.href}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))} */}
                  <Menu.Item>
                    <button
                      onClick={() => Auth.signOut()}
                      className={classNames(
                        "block w-full px-4 cursor-pointer hover:bg-gray-100 py-2 text-left text-sm text-gray-700"
                      )}
                    >
                      Sign Out
                    </button>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      <main
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: "url(/hero-bg.jpg)",
          backgroundColor: "#f9fafc",
          backdropFilter: "blue(10%) saturate(180px)",
        }}
        className="flex-1 bg-opacity-50 bg-[#f9fafc] min-h-screen"
      >
        {children}
      </main>
    </div>
  );
};

export { MainLayout };
