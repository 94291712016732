import { EyeIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import PrimaryButton from "../../../core/components/Button";
import { deletePress, getAllPress } from "../../../data/press.data";
import withProtected from "../../../core/hoc/withProtected";
import { CenteredContainer } from "../../../core/layouts";
import { Press } from "../../../types";

const AllPressPage = () => {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [press_releases, setPressReleases] = useState<Press[]>([]);
  const [deleting, setDeleting] = useState({
    value: false,
    slug: "",
  });

  const fetchPress = async () => {
    const res = await getAllPress();
    if (res.data) {
      setPressReleases(res.data);
    }
  };

  const deletePressHandle = async (slug: string) => {
    try {
      setDeleting({
        slug: slug,
        value: true,
      });
      const response = await deletePress(slug);

      if (response.success) {
        toast.success(response.message);
        fetchPress();
      } else {
        toast.error(response.message);
      }
      setDeleting({
        slug: slug,
        value: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPress();
  }, []);

  return (
    <>
      <CenteredContainer
        title="Press Releases"
        subtitle="All press releases"
        paths={[
          {
            name: "Press",
            route: "/press",
            asLink: false,
          },
        ]}
        actions={
          <Link to={"/press/add"}>
            <PrimaryButton
              title="Add Press"
              icon={<PlusIcon className="w-5 h-5" />}
            />
          </Link>
        }
      >
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {press_releases.map((press, index) => (
            <motion.li
              layoutId={`${index}`}
              key={press.slug}
              className="col-span-1 cursor-pointer hover:scale-105  transition-all duration-200 bg-white rounded-lg shadow divide-y divide-gray-200"
            >
              <div
                onClick={() => {
                  setSelectedId(`${index}`);
                }}
                className="w-full h-[150px] overflow-hidden rounded-t-lg  flex justify-center items-center min-h-[150px]"
              >
                <img
                  className="object-cover w-full"
                  src={`${process.env.REACT_APP_STORES_ASSETS}/${press.thumbnail}`}
                  alt=""
                />
              </div>
              <div className="w-full flex items-center justify-between p-6 space-x-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="text-gray-900 text-sm font-medium truncate">
                      {press.title}
                    </h3>
                  </div>
                  <p className="mt-1 text-gray-500 text-sm truncate">
                    {press.subtitle}
                  </p>

                  <div className="text-xs press-description mt-3">
                    {press.description}
                  </div>
                </div>
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="w-0 flex-1 flex">
                    <button
                      onClick={() => {
                        setSelectedId(`${index}`);
                      }}
                      className="relative transition-all hover:bg-primary-blue hover:bg-opacity-5  -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-primary-blue font-medium border border-transparent rounded-bl-lg hover:text-primary-blue-dark"
                    >
                      <EyeIcon
                        className="w-5 h-5 text-primary-blue"
                        aria-hidden="true"
                      />
                      <span className="ml-3">View</span>
                    </button>
                  </div>
                  <div className="-ml-px w-0 flex-1 flex">
                    <button
                      disabled={deleting.value}
                      onClick={() => deletePressHandle(press.slug)}
                      className="relative hover:bg-red-100 transition-all w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-red-500 font-medium border border-transparent rounded-br-lg hover:text-red-600"
                    >
                      {deleting.value && deleting.slug === press.slug ? (
                        <>
                          <svg
                            className="w-5 h-5  text-red-500 animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx={12}
                              cy={12}
                              r={10}
                              stroke="currentColor"
                              strokeWidth={4}
                            />
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                          </svg>
                        </>
                      ) : (
                        <div className="inline-flex">
                          <TrashIcon
                            className="w-5 h-5 text-red-500"
                            aria-hidden="true"
                          />
                          <span className="ml-3">Delete</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </motion.li>
          ))}
        </ul>
      </CenteredContainer>
      <AnimatePresence>
        {selectedId && (
          <div
            onClick={() => {
              setSelectedId(null);
            }}
            className="w-screen z-10 bg-black bg-opacity-50 backdrop-blur-sm  fixed top-0 left-0 min-h-screen h-screen flex flex-col items-center justify-center"
          >
            <motion.div
              initial={{
                scale: 0,
                opacity: 0,
              }}
              animate={{
                scale: 1,
                opacity: 1,
              }}
              key={press_releases[Number.parseInt(selectedId)].slug}
              className="w-1/2 cursor-pointer bg-white rounded-lg overflow-hidden shadow divide-y divide-gray-200"
            >
              <div className="w-full h-[500px] overflow-hidden flex justify-center items-center min-h-[300px]">
                <img
                  className="object-cover w-full"
                  src={`${process.env.REACT_APP_STORES_ASSETS}/${
                    press_releases[Number.parseInt(selectedId)].thumbnail
                  }`}
                  alt=""
                />
              </div>
              <motion.div className="w-full flex items-center justify-between p-6 space-x-6">
                <div className="flex-1">
                  <div className="flex items-center space-x-3">
                    <h3 className="text-gray-900 text-sm font-medium">
                      {press_releases[Number.parseInt(selectedId)].title}
                    </h3>
                  </div>
                  <p className="mt-1 text-gray-500 text-sm">
                    {press_releases[Number.parseInt(selectedId)].subtitle}
                  </p>

                  <div className="text-xs press-description mt-3">
                    {press_releases[Number.parseInt(selectedId)].description}
                  </div>

                  <div>
                    <a
                      target={"_blank"}
                      className="text-xs px-3 py-1 rounded-full bg-primary-blue text-white cursor-pointer"
                      href={press_releases[Number.parseInt(selectedId)].url}
                      rel="noreferrer"
                    >
                      Visit URL
                    </a>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default withProtected(AllPressPage);
