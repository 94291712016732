/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import { PencilAltIcon } from "@heroicons/react/outline";

import classNames from "classnames";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../../../core/components/Breadcrumb";
import { editCategory, getCategory } from "../../../data/categories.data";
import { Switch } from "@headlessui/react";

import withProtected from "../../../core/hoc/withProtected";
import { Category } from "../../../types";

interface Editable<T> {
  editable: boolean;
  value: T;
  url?: string | null;
}

const EditCategory = () => {
  const [cover, setCover] = useState<Editable<File | null>>({
    editable: false,
    value: null,
    url: null,
  });

  const [category, setCategory] = useState<Category | null>(null);

  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const params = useParams();

  const updateCategory = async (
    fieldName: keyof Category,
    value: any
  ): Promise<boolean> => {
    setLoading(true);
    const response = await editCategory(category?.slug!, {
      [fieldName]: value,
    });
    setLoading(false);

    if (response.status === 200 && response.data.success) {
      toast.success(response.data.message);

      fetchCategory(category?.slug!);
      return true;
    } else {
      toast.error(response.data.message ?? "Something went wrong");
      fetchCategory(category?.slug!);
      return false;
    }
  };

  const fetchCategory = async (slug: string) => {
    getCategory(slug!)
      .then((category) => {
        setCategory(category!);

        setIsActive(category?.active || false);
        setCover({
          ...cover,
          editable: false,
          url: category?.cover ?? null,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const { slug } = params;

    fetchCategory(slug!);
  }, [params]);

  return (
    <div className="pt-6 pb-20">
      <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumb
            paths={[
              {
                name: "Categories",
                route: "/categories",
              },
              {
                name: category?.name!,
                route: `/categories/edit/${category?.slug}`,
                asLink: false,
              },
            ]}
          />
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Edit Category - {category?.name}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                You can edit category here
              </p>
            </div>

            <div>
              <div className="flex items-center space-x-4">
                <p className="text-sm">Inactive</p>
                <Switch
                  disabled={loading}
                  checked={isActive}
                  onChange={async (value) => {
                    setIsActive(value);

                    const success = await updateCategory("active", value);

                    if (success) {
                      setIsActive(value);
                    } else {
                      setIsActive(!value);
                    }
                  }}
                  className={classNames(
                    isActive ? "bg-green-500" : "bg-red-500",
                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isActive ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                    )}
                  />
                </Switch>
                <p className="text-sm">Active</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-8">
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                  <InputEdit
                    refetch={(slug) => fetchCategory(slug)}
                    fieldName={"name"}
                    category={category!}
                    type={"text"}
                    label={"Category Name"}
                  />
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="slug"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Slug
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="flex items-center space-x-3 sm:mt-px sm:pt-2">
                        <div className="text-sm">
                          {category?.slug?.toString()}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Cover Image
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {!cover.editable && cover.url ? (
                        <div className="flex items-center space-x-4">
                          <div className="min-w-[300px] max-w-[300px] mt-4 rounded-md overflow-hidden">
                            <img
                              className="object-cover min-w-[300px] w-[300px] h-[300px]"
                              src={`${
                                cover.editable
                                  ? cover.url
                                  : `${process.env.REACT_APP_STORES_ASSETS}/${cover.url}`
                              }`}
                            />
                          </div>

                          <button
                            onClick={() => {
                              setCover({
                                editable: true,
                                value: null,
                                url: null,
                              });
                            }}
                            className="inline-flex items-center justify-center w-8 h-8 min-w-[32px] min-h-[32px] space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-full shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto "
                          >
                            <PencilAltIcon className="w-5 h-5 " />
                          </button>
                        </div>
                      ) : (
                        <div>
                          <CoverImageDropZone
                            onDrop={(file) => {
                              setCover({
                                editable: true,
                                value: file,
                                url: file["preview"],
                              });
                            }}
                          />
                          {cover.editable && cover.value && (
                            <div className="mt-4">
                              <button
                                onClick={coverUpload}
                                disabled={loading}
                                className="inline-flex items-center justify-center px-4 py-2 space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-md shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto "
                              >
                                {loading && (
                                  <>
                                    <svg
                                      className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        className="opacity-25"
                                        cx={12}
                                        cy={12}
                                        r={10}
                                        stroke="currentColor"
                                        strokeWidth={4}
                                      />
                                      <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      />
                                    </svg>
                                  </>
                                )}

                                <span>Update</span>
                              </button>
                              <button
                                disabled={loading}
                                onClick={() => {
                                  setCover({
                                    editable: false,
                                    value: null,
                                    url: category?.cover!,
                                  });
                                }}
                                type="button"
                                className="px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>

              <InputEdit
                refetch={(slug) => fetchCategory(slug)}
                fieldName={"description"}
                category={category!}
                textarea
                type={"text"}
                label={"Description"}
              />
            </div>

            <div className="pt-5">
              {/* <div className="flex justify-end space-x-3">
                <button
                  disabled={loading}
                  type="button"
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
                >
                  Cancel
                </button>
                <PrimaryButton
                  onClick={onSubmit}
                  disabled={loading}
                  loading={loading}
                  title={"Submit"}
                ></PrimaryButton>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface InputEditProps {
  category: Category;
  type: string;
  fieldName: keyof Category;
  label: string;
  textarea?: boolean;
  refetch: (slug: string) => Promise<void>;
}

const InputEdit = ({
  category,
  type,
  fieldName,
  label,
  textarea = false,
  refetch,
}: InputEditProps) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<Editable<any>>({
    editable: false,
    value: null,
  });

  const onUpdate = async () => {
    if (value.value === null || value.value === "") {
      return toast.error(`Field "${fieldName}" must be required`);
    }

    setLoading(true);
    const response = await editCategory(category?.slug!, {
      [fieldName]: value.value,
    });
    setLoading(false);

    if (response.status === 200 && response.data.success) {
      toast.success(response.data.message);
      setValue({
        ...value,
        editable: false,
      });
      refetch(category.slug);
    } else {
      toast.error(response.data.message ?? "Something went wrong");
      refetch(category.slug);
    }
  };

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        {value.editable ? (
          <div className="flex items-center justify-between">
            {textarea ? (
              <div className="flex flex-col w-full">
                <textarea
                  disabled={loading}
                  onChange={(event) => {
                    setValue({
                      editable: true,
                      value: event.target.value,
                    });
                  }}
                  rows={3}
                  className="block w-full max-w-lg border border-gray-300 rounded-md shadow-sm focus:ring-primary-teal focus:border-primary-teal sm:text-sm"
                  defaultValue={category?.[fieldName]?.toString()}
                />
                <p className="mt-2 text-sm text-gray-500">
                  Write a few sentences about store.
                </p>
              </div>
            ) : (
              <div className="relative flex items-center w-1/2 max-w-lg space-x-4 rounded-md shadow-sm">
                <input
                  defaultValue={category?.[fieldName]?.toString()}
                  onChange={(event) => {
                    setValue({
                      editable: true,
                      value: event.target.value,
                    });
                  }}
                  disabled={loading}
                  type={type}
                  autoComplete="off"
                  className={classNames(
                    "focus:ring-primary-teal focus:border-primary-teal",
                    "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm"
                  )}
                />
              </div>
            )}
            <div>
              <button
                disabled={loading}
                onClick={onUpdate}
                className="inline-flex items-center justify-center px-4 py-2 space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-md shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto "
              >
                {loading && (
                  <>
                    <svg
                      className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx={12}
                        cy={12}
                        r={10}
                        stroke="currentColor"
                        strokeWidth={4}
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  </>
                )}

                <span>Update</span>
              </button>
              <button
                disabled={loading}
                onClick={() => {
                  setValue({
                    editable: false,
                    value: category?.[fieldName]!.toString(),
                  });
                }}
                type="button"
                className="px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="flex items-center space-x-3 sm:mt-px sm:pt-2">
            <div className="text-sm">{category?.[fieldName]?.toString()}</div>
            <div>
              <button
                onClick={() => {
                  setValue({
                    editable: true,
                    value: category?.[fieldName]?.toString(),
                  });
                }}
                className="inline-flex items-center justify-center w-8 h-8 min-w-[32px] min-h-[32px] space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-full shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto "
              >
                <PencilAltIcon className="w-5 h-5 " />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withProtected(EditCategory);
