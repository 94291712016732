import { Banner, BannerPlace } from "../types"
import axios from "../utils/axios"
import BaseResponse from "./base_response"

interface GetAllBanner extends BaseResponse {
  data?: {
    banners: Banner[]
    count: number
  }
}

interface GetSingleBanner extends BaseResponse {
  data?: Banner
}

interface GetAllBannerPlaces extends BaseResponse {
  data?: BannerPlace[]
}

interface CreateBannerPlaceResponse extends BaseResponse {
  data?: {}
}

interface GetSingleBannerPlaceResponse extends BaseResponse {
  data?: Banner[]
}

const createBanner = (data: any) =>
  axios.post<BaseResponse>("/banner/item/create", data).then((res) => res.data)

const getAllBanner = () =>
  axios.get<GetAllBanner>("/banner/item/get-all").then((res) => res.data)

const getSingleBanner = (slug: string) =>
  axios
    .get<GetSingleBanner>(`/banner/item/get`, {
      params: {
        slug,
      },
    })
    .then((res) => res.data)

const editBanner = (data: any) =>
  axios.patch<BaseResponse>("banner/item/edit", data).then((res) => res.data)

const clearBanner = (data: any) =>
  axios.patch<BaseResponse>("banner/clear", data).then((res) => res.data)

const deleteBanner = (slug: string) =>
  axios
    .delete<BaseResponse>(`/banner/item/delete`, {
      data: {
        slug,
      },
    })
    .then((res) => res.data)

const getSingleBannerPlace = async (
  place: string,
  page: string
): Promise<GetSingleBannerPlaceResponse> => {
  const response = axios.get<GetSingleBannerPlaceResponse>(
    `/banner/place/get`,
    {
      params: {
        place,
        page,
      },
    }
  )

  return response.then((res) => res.data)
}

const getAllBannerPlaces = async () =>
  axios.get<GetAllBannerPlaces>("/banner/place/get-all").then((res) => res.data)

const createBannerPlace = async (data: any) =>
  axios
    .post<CreateBannerPlaceResponse>("/banner/place/create", data)
    .then((res) => res.data)

const editBannerPlace = async (data: any) =>
  axios
    .patch<CreateBannerPlaceResponse>("/banner/place/edit", data)
    .then((res) => res.data)

const deleteBannerPlace = async (data: any) => {
  return axios
    .delete<CreateBannerPlaceResponse>("/banner/place/delete", {
      data: {
        place: data.place,
        page: data.page,
      },
    })
    .then((res) => res.data)
}

export {
  getAllBanner,
  createBanner,
  deleteBanner,
  editBanner,
  clearBanner,
  getSingleBanner,
  getSingleBannerPlace,
  getAllBannerPlaces,
  createBannerPlace,
  editBannerPlace,
  deleteBannerPlace,
}
