import withProtected from "../../../core/hoc/withProtected"
import React, { useEffect, useState } from "react"
import { CenteredContainer } from "../../../core/layouts"
import { useForm } from "react-hook-form"
import { Category } from "../../../types"
import { getAllCategories } from "../../../data/categories.data"
import { Combobox } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline"
import classNames from "classnames"
import PrimaryButton from "../../../core/components/Button"
import { useNavigate } from "react-router-dom"
import { createBannerPlace } from "../../../data/banner_data"
import toast from "react-hot-toast"

interface FieldValues {
  page: string
  place: string
}

const AddPlace = () => {
  const navigate = useNavigate()

  const { handleSubmit, setValue } = useForm<FieldValues>()

  async function onSubmit(data: FieldValues) {
    setLoading(true)

    try {
      const response = await createBannerPlace(data)

      if (response.success) {
        toast.success(response.message)
        setLoading(false)
        navigate("/banners")
      } else {
        setLoading(false)
        toast.error(response.message ?? "Error occurred")
      }
    } catch (error: any) {
      setLoading(false)
      if (error.message === "Request failed with status code 401") {
        toast.error("Banner Place already exists")
        return
      }
      toast.error(error.message ?? "Error occurred")
    }
  }

  const [categories, setCategories] = useState<Category[]>([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    getAllCategories()
      .then((res) => {
        setLoading(false)
        setCategories(res)
      })
      .catch((err) => {})
  }, [])

  const [pages, setPages] = useState<string[]>([])
  const [filteredPages, setFilteredPages] = useState<string[]>([])
  const [selectedPage, setSelectedPage] = useState<string>("index")
  useEffect(() => {
    if (categories.length > 0 && !loading) {
      const pages = categories.map((category) => category.name)

      setPages(pages.concat("index"))
      setFilteredPages(pages.concat("index"))
      setSelectedPage("index")
    }
  }, [categories])

  const pageFinderTypeFilterHandler = (text: string) => {
    setFilteredPages(
      pages.filter((page) =>
        page.includes(text[0].toUpperCase() + text.slice(1))
      )
    )
  }

  const places = ["top", "bottom"]
  const [selectedPlace, setSelectedPlace] = useState<string>("top")
  const [filteredPlaces, setFilteredPlaces] = useState<string[]>(places)
  const placeFinderTypeFilterHandler = (text: string) => {
    setFilteredPlaces(places.filter((place) => place.includes(text)))
  }

  useEffect(() => {
    setValue("page", selectedPage)
    setValue("place", selectedPlace)
  }, [selectedPage, selectedPlace])

  return (
    <CenteredContainer
      title="Add Banner Placements"
      subtitle="You can Add Banner Placements here."
      paths={[
        {
          name: "Banner",
          route: "/banners",
          asLink: true,
        },
        {
          name: "Add Place",
          route: "/banners/add-place",
          asLink: true,
        },
      ]}
    >
      <div className="flex flex-col mt-8">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-8 divide-y divide-gray-200"
            >
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="buttonText"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Page to Display
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="relative flex max-w-lg rounded-md ">
                    <Combobox
                      as="div"
                      value={selectedPage}
                      onChange={setSelectedPage}
                    >
                      <div className="relative mt-1">
                        <Combobox.Input
                          // ref={inputRef}
                          className="w-full py-2 pl-3 pr-10 bg-white border border-gray-300 rounded-md shadow-sm focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-prborder-primary-blue sm:text-sm"
                          // onChange={(event) => setQuery(event.target.value)}
                          onChange={(event) => {
                            pageFinderTypeFilterHandler(event.target.value)
                          }}
                          displayValue={(selectedPage: string) => selectedPage}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                          <SelectorIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </Combobox.Button>

                        <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {filteredPages.map((singlePage) => (
                            <Combobox.Option
                              key={singlePage}
                              value={singlePage}
                              className={({ active }) =>
                                classNames(
                                  "relative cursor-default select-none py-2 pl-8 pr-4",
                                  active
                                    ? "bg-primary-blue text-primary-teal"
                                    : "text-gray-900"
                                )
                              }
                            >
                              {({ active, selected }) => (
                                <>
                                  <span
                                    className={classNames(
                                      "block truncate",
                                      selected && "font-semibold"
                                    )}
                                  >
                                    {singlePage}
                                  </span>

                                  {selected && (
                                    <span
                                      className={classNames(
                                        "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                        active
                                          ? "text-white"
                                          : "text-primary-blue"
                                      )}
                                    >
                                      <CheckIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      </div>
                    </Combobox>
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="buttonText"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Place to Display
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="relative flex max-w-lg rounded-md ">
                    <Combobox
                      as="div"
                      value={selectedPlace}
                      onChange={setSelectedPlace}
                    >
                      <div className="relative mt-1">
                        <Combobox.Input
                          // ref={inputRef}
                          className="w-full py-2 pl-3 pr-10 bg-white border border-gray-300 rounded-md shadow-sm focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-prborder-primary-blue sm:text-sm"
                          // onChange={(event) => setQuery(event.target.value)}
                          onChange={(event) => {
                            placeFinderTypeFilterHandler(event.target.value)
                          }}
                          displayValue={(selectedPage: string) => selectedPage}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                          <SelectorIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </Combobox.Button>

                        <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {filteredPlaces.map((singlePlace) => (
                            <Combobox.Option
                              key={singlePlace}
                              value={singlePlace}
                              className={({ active }) =>
                                classNames(
                                  "relative cursor-default select-none py-2 pl-8 pr-4",
                                  active
                                    ? "bg-primary-blue text-primary-teal"
                                    : "text-gray-900"
                                )
                              }
                            >
                              {({ active, selected }) => (
                                <>
                                  <span
                                    className={classNames(
                                      "block truncate",
                                      selected && "font-semibold"
                                    )}
                                  >
                                    {singlePlace}
                                  </span>

                                  {selected && (
                                    <span
                                      className={classNames(
                                        "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                        active
                                          ? "text-white"
                                          : "text-primary-blue"
                                      )}
                                    >
                                      <CheckIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      </div>
                    </Combobox>
                  </div>
                </div>
              </div>
              <div className="pt-5">
                <div className={`flex justify-end`}>
                  <div className="flex justify-end space-x-3">
                    <button
                      onClick={() => {
                        navigate("/banners")
                      }}
                      disabled={loading}
                      type="button"
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
                    >
                      Cancel
                    </button>
                    <PrimaryButton
                      disabled={loading}
                      loading={loading}
                      type="submit"
                      title={"Save Changes"}
                    ></PrimaryButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </CenteredContainer>
  )
}

export default withProtected(AddPlace)
