import React, { useEffect, useState } from "react"
import { CenteredContainer } from "../../../core/layouts"
import withProtected from "../../../core/hoc/withProtected"
import KeywordPillSearchBox from "../components/KeywordPillSearchBox"
import { editKeyword, getAllKeywords } from "../../../data/keywords_data"
import { Keyword } from "../../../types"
import CommonDisclosure from "../components/CommonDisclosure"

interface keyword {
  keyword: string
  active: boolean
}

const ListKeywords = () => {
  const [keywords, setKeywords] = useState<Keyword[]>([])
  const [results, setResults] = useState<Keyword[]>([])

  useEffect(() => {
    fetchKeywords()
  }, [])

  const fetchKeywords = async () => {
    setKeywords([])
    getAllKeywords().then((res) => {
      setKeywords(res.data.keywords)
      setResults(res.data.keywords)
    })
  }

  const onClickKeywordPill = async (keyword: Keyword) => {
    // setKeywords(
    //   Keywords.map((item: keyword) => {
    //     if (item.keyword === keyword.keyword) {
    //       return {
    //         ...item,
    //         active: !item.active,
    //       }
    //     }
    //     return item
    //   }),
    // )


    const EditPromise = new Promise((resolve, reject) => {
      editKeyword({
        slug: keyword.slug,
        active: !keyword.active,
        description: null,
        name: null,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })

    // EditPromise.then((res) => {
    //   setTimeout(() => {
    //     fetchKeywords()
    //   }, 500)
    // })

    await EditPromise

  }


  const onChangeTextHandler = (searchValue: string) => {
    const newKeywords = results.filter((keyword) => {
      return keyword.name.toLowerCase().includes(searchValue)
    })

    setKeywords(newKeywords)
  }

  const [keywordsWithDate, setKeywordsWithDate] = useState<any>([])

  useEffect(() => {
    //   group keywords by date with the same date
    type GroupedData = { [date: string]: Keyword[] }

    const groupedData = keywords.reduce((acc: GroupedData, curr) => {
      const date = curr.createdAt.split("T")[0]
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(curr)
      return acc
    }, {})

    //   convert grouped data to array of objects
    const groupedDataArray = Object.keys(groupedData).map((date) => {
      return {
        date,
        keywords: groupedData[date],
        count: groupedData[date].length,
      }
    })

    //   sort the array by date newest to oldest
    const sortedGroupedDataArray = groupedDataArray.sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime()
    })

    setKeywordsWithDate(sortedGroupedDataArray)
  }, [keywords])

  return (
    <CenteredContainer
      title="List Keywords"
      subtitle="You can enable or disable keywords here"
      paths={[
        {
          name: "Keywords",
          route: "/keywords",
          asLink: true,
        },
      ]}
    >
      <div className="pt-6 pb-10 ">
        <div className=" mx-auto max-w-7xl sm:px-6 md:px-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <KeywordPillSearchBox onChangeTextHandler={onChangeTextHandler} />
          </div>
        </div>
      </div>
      <div className={`flex flex-wrap w-full pt-4 pb-4 max-w-7xl`}>
        {keywordsWithDate.map((groupedKeywords: any) => {
          return (
            <CommonDisclosure
              date={groupedKeywords.date}
              key={groupedKeywords.date}
              count={groupedKeywords.count}
              onClickHandler={onClickKeywordPill}
              keywords={groupedKeywords.keywords}
            />
          )
        })}
      </div>
    </CenteredContainer>
  )
}

export default withProtected(ListKeywords)
