/* eslint-disable no-useless-escape */
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useState } from "react";
import classNames from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import withPublic from "../../core/hoc/withPublic";

interface FieldValues {
  email: string;
  password: string;
}

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();

  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);

  const onSignin = (data: FieldValues) => {
    setError(null);
    setLoading(true);
    Auth.signIn(data.email, data.password)
      .then((user) => {
        if (user) {
          window.location.replace("/");
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  return (
    <>
      <div className="flex flex-col justify-center h-screen min-h-full py-12 bg-primary-blue-dark sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
            <div className="pb-8 sm:mx-auto sm:w-full sm:max-w-md">
              <img
                className="w-[200px] mx-auto "
                src={"/logo-horizontal-dark.png"}
                alt="Mintpay Logo"
              />
              {/* <h2 className="mt-2 text-2xl font-medium text-center text-primary-blue-dark">
                Webstore Admin
              </h2> */}
            </div>
            <form onSubmit={handleSubmit(onSignin)} className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <div className="relative rounded-md shadow-sm">
                    <input
                      {...register("email", {
                        required: "Email must be not empty!",
                        pattern: {
                          value:
                            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                          message: "Email address not valid",
                        },
                      })}
                      autoComplete="email"
                      className={classNames(
                        errors.email || error
                          ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
                          : "placeholder-gray-400 border-gray-300 focus:ring-primary-blue focus:border-primaring-primary-blue ",
                        "block w-full px-3 py-2  border  rounded-md appearance-none focus:outline-none sm:text-sm"
                      )}
                    />

                    {(errors.email || error) && (
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <ExclamationCircleIcon
                          className="w-5 h-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </div>
                  {errors.email && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {errors.email.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <div className="relative rounded-md shadow-sm">
                    <input
                      type={"password"}
                      {...register("password", {
                        required: "Password cannot be empty",
                      })}
                      className={classNames(
                        errors.password || error
                          ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
                          : "placeholder-gray-400 border-gray-300 focus:ring-primary-blue focus:border-primaring-primary-blue ",
                        "block w-full px-3 py-2  border  rounded-md appearance-none focus:outline-none sm:text-sm"
                      )}
                    />
                    {(errors.password || error) && (
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <ExclamationCircleIcon
                          className="w-5 h-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </div>
                  {errors.password && (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="password-error"
                    >
                      {errors.password.message}
                    </p>
                  )}
                </div>
              </div>

              {error && (
                <div className="px-5 py-4 rounded-md bg-red-50">
                  <p className="text-sm text-red-500">
                    {error?.message ?? "Error occured"}
                  </p>
                </div>
              )}

              {/* <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-primary-blue"
                  />
                  <label
                    htmlFor="remember-me"
                    className="block ml-2 text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <a
                    href="#"
                    className="font-medium text-primary-blue hover:text-pr"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div> */}

              <div>
                <button
                  disabled={loading}
                  type="submit"
                  className={classNames(
                    loading
                      ? "cursor-not-allowed bg-primary-teal text-primary-blue opacity-40"
                      : "cursor-pointer text-white hover:bg-primary-blue-dark",
                    "flex justify-center w-full px-4 py-2 text-sm font-medium bg-primary-blue border border-transparent rounded-md shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-blue"
                  )}
                >
                  {loading ? "Signing in..." : "Sign in"}
                </button>
              </div>
            </form>

            <div className="mt-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withPublic(LoginPage);
