import { forwardRef } from "react";
import Skeleton from "react-loading-skeleton";

const TableLoadingRows = forwardRef(() => {
  return (
    <table className="w-full bg-white">
      <tbody>
        <tr>
          <td className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
            <div className="flex items-center">
              <div className="flex-shrink-0 w-10 h-10">
                <Skeleton className="w-10 h-10 rounded-md" />
              </div>
              <div className="ml-4">
                <Skeleton height={15} width={200} />
              </div>
            </div>
          </td>

          <td className="flex px-3 py-4 space-x-2 text-sm text-gray-500 whitespace-nowrap">
            <Skeleton height={15} width={50} />
            <Skeleton height={15} width={50} />
            <Skeleton height={15} width={50} />
          </td>
          <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
            <Skeleton height={15} width={50} />
          </td>

          <td className="relative py-4 pl-3 pr-4 space-x-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
            <Skeleton height={15} width={80} />
          </td>
        </tr>
        <tr>
          <td className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
            <div className="flex items-center">
              <div className="flex-shrink-0 w-10 h-10">
                <Skeleton className="w-10 h-10 rounded-md" />
              </div>
              <div className="ml-4">
                <Skeleton height={15} width={200} />
              </div>
            </div>
          </td>

          <td className="flex px-3 py-4 space-x-2 text-sm text-gray-500 whitespace-nowrap">
            <Skeleton height={15} width={50} />
            <Skeleton height={15} width={50} />
            <Skeleton height={15} width={50} />
          </td>
          <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
            <Skeleton height={15} width={50} />
          </td>

          <td className="relative py-4 pl-3 pr-4 space-x-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
            <Skeleton height={15} width={80} />
          </td>
        </tr>
      </tbody>
    </table>
  );
});

export { TableLoadingRows };
