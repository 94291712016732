import ReactDOM from "react-dom";
import App from "./App";
import "./styles/global.scss";
import "react-loading-skeleton/dist/skeleton.css";
import { Amplify } from "aws-amplify";
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  },
});

ReactDOM.render(
  <AmplifyProvider>
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  </AmplifyProvider>,
  document.getElementById("root")
);
