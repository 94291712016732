import { FC, ReactNode } from "react";
import { Breadcrumb } from "../components/Breadcrumb";

type BreadcrumbPath = {
  name: string;
  route: string;
  asLink: boolean;
};

interface ICenteredContainer {
  paths: BreadcrumbPath[];
  title: string;
  subtitle?: string;
  actions?: ReactNode;
}

export const CenteredContainer: FC<ICenteredContainer> = ({
  children,
  paths,
  title,
  subtitle,
  actions,
}) => {
  return (
    <div className="pt-6 pb-20">
      <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumb paths={paths} />
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
              {subtitle && (
                <p className="mt-2 text-sm text-gray-700">{subtitle}</p>
              )}
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">{actions}</div>
          </div>
          <div className="mt-5">{children}</div>
        </div>
      </div>
    </div>
  );
};
