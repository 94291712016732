import { AxiosRequestHeaders } from "axios";
import axios from "axios";
import { Auth } from "aws-amplify";

const AWS_API_URL = process.env.REACT_APP_AWS_API_URL;

const instance = axios.create({
  baseURL: `${AWS_API_URL}`,
});

instance.interceptors.request.use(
  async (config) => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const headers: AxiosRequestHeaders = {
      Authorization: token,
    };

    config.headers = headers;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
