import React, { useState } from "react"
import { Keyword } from "../../../types"
import { slugifyWord } from "../../../data/stores.data"

interface KeywordPillProps {
  keyword: Keyword
  onClickHandler?: (keyword: Keyword) => void
}

const KeywordPill = ({ keyword, onClickHandler }: KeywordPillProps) => {
  const [isActive , setIsActive] = useState(keyword.active)

  return (
    <div
      className={`flex px-2 py-1.5 space-x-4 rounded-full m-1 cursor-pointer ${
        isActive ? "bg-primary-blue text-blue-50" : "bg-gray-200"
      }`}
      onClick={() => {
        onClickHandler && onClickHandler(keyword)
        setIsActive(!isActive)
      }}
    >
      {slugifyWord(keyword.name).replace(/-/g, " ")}
    </div>
  )
}

export default KeywordPill
