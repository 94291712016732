/* eslint-disable react-hooks/exhaustive-deps */
import { PlusIcon } from "@heroicons/react/outline";
import { ChangeEventHandler, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getStores,
  searchStoresAndCategories,
} from "../../../data/stores.data";
import { Store } from "../../../types";
import PrimaryButton from "../../../core/components/Button";
import StoreListItem from "../components/StoreListItem";
import withProtected from "../../../core/hoc/withProtected";
import { Breadcrumb } from "../../../core/components/Breadcrumb";
import { TableLoadingRows } from "../components/TableLoadingRows";
import { useInView } from "react-intersection-observer";

const StoresPage = () => {
  const [stores, setStores] = useState<Store[]>([]);
  const [filteredStores, setFilteredStores] = useState<Store[] | null>(null);

  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState<number | null>(null);
  const [limit] = useState(16);
  const [isLast, setIsLast] = useState(false);
  const [startKey, setStartKey] = useState(null);

  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const { ref, inView } = useInView();

  const fetchMore = () => {
    if (isLast || !startKey) return;

    getStores(limit, JSON.stringify(startKey)).then((res) => {
      setStores([...stores, ...res?.data?.stores!]);
      if (res?.data?.lastKey) {
        setStartKey(res?.data?.lastKey);
      } else {
        setIsLast(true);
      }
    });
  };

  const initalFetch = async () => {
    setLoading(true);
    getStores(limit)
      .then((res) => {
        setStartKey(res?.data?.lastKey);
        setTotal(res?.data?.total!);
        setStores(res?.data?.stores!);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    initalFetch();
  }, [limit]);

  useEffect(() => {
    if (inView) {
      fetchMore();
    }
  }, [inView]);

  const handleSearch: ChangeEventHandler<HTMLInputElement> = async ({
    target,
  }) => {
    const text = target.value;
    const searchText = text.toLowerCase();
    setSearchQuery(searchText);

    if (searchText === "") {
      setFilteredStores(null);
      setSearchQuery(null);
    }

    const filteredStores = await searchStoresAndCategories(
      16,
      null,
      searchText,
      "stores"
    );

    setFilteredStores(filteredStores.data.data?.stores!);
  };

  return (
    <div className="pt-6 pb-20">
      <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumb
            paths={[
              {
                name: "Stores",
                route: "/stores",
                asLink: false,
              },
            ]}
          />
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Stores</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the stores in Mintpay
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <Link to={"/stores/add"}>
                <PrimaryButton
                  title="Add Store"
                  icon={<PlusIcon className="w-5 h-5" />}
                />
              </Link>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="w-1/3 mt-5">
              <div>
                <div className="relative flex items-center mt-1">
                  <input
                    onChange={handleSearch}
                    type="text"
                    name="search"
                    id="search"
                    placeholder="Search with store name"
                    className="block w-full pr-12 border-gray-300 rounded-md shadow-sm focus:ring-primary-teal focus:border-primary-teal sm:text-sm"
                  />
                  <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                    <kbd className="inline-flex items-center px-2 font-sans text-sm font-medium text-gray-400 border border-gray-200 rounded">
                      ⌘K
                    </kbd>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-8">
            <div className="flex pb-4 ">
              <div className="hidden sm:block">
                {searchQuery ? (
                  <p className="text-sm text-gray-500">
                    <span className="font-medium">
                      {filteredStores?.length}
                    </span>{" "}
                    Results for{" "}
                    <span className="font-medium">"{searchQuery}"</span>
                  </p>
                ) : (
                  <p className="text-sm text-gray-500">
                    Showing <span className="font-medium">1</span> to{" "}
                    <span className="font-medium">{stores?.length}</span> of{" "}
                    <span className="font-medium">{total}</span> results
                  </p>
                )}
              </div>
            </div>
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden border shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="w-full min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Categories
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>

                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {filteredStores && filteredStores.length === 0 && (
                        <tr>
                          <td
                            colSpan={4}
                            className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6"
                          >
                            <span className="text-gray-500">
                              No matched results for the query
                            </span>
                          </td>
                        </tr>
                      )}
                      {!loading &&
                        filteredStores?.map((store) => (
                          <StoreListItem key={store.slug} store={store} />
                        ))}
                      {!loading &&
                        filteredStores === null &&
                        stores.map((store) => (
                          <StoreListItem store={store} key={store.slug} />
                        ))}
                    </tbody>
                  </table>
                  {loading && <TableLoadingRows />}
                  {!loading && !isLast && filteredStores === null && (
                    <div className="w-full bg-white" ref={ref}>
                      <TableLoadingRows />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withProtected(StoresPage);
