import { Combobox } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import PrimaryButton from "../../../core/components/Button";
import { getAllCategories } from "../../../data/categories.data";
import { Category } from "../../../types";

function AddCategoryStore({
  onAddCategory,
}: {
  storeSlug?: string;
  onAddCategory: (categories: Category) => void;
}) {
  const [query, setQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [categories, setCategories] = useState<Category[]>([]);

  const inputRef = useRef<any>(null);

  const filteredCategories =
    query === ""
      ? categories
      : categories.filter((category) => {
          return category.name.toLowerCase().includes(query.toLowerCase());
        });

  const assignNewCategory = async () => {
    if (!selectedCategory) {
      return toast.error("Select a Category");
    }

    onAddCategory(selectedCategory);
    setSelectedCategory(undefined);
    inputRef.current.value = "";
  };

  useEffect(() => {
    getAllCategories().then((res) => {
      setCategories(res);
    });
  }, []);

  return (
    <div className="flex items-center space-x-3">
      <Combobox
        as="div"
        value={selectedCategory}
        onChange={setSelectedCategory}
      >
        <div className="relative mt-1">
          <Combobox.Input
            ref={inputRef}
            className="w-full py-2 pl-3 pr-10 bg-white border border-gray-300 rounded-md shadow-sm focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-prborder-primary-blue sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(category: any) => category.name}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
            <SelectorIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {filteredCategories.length > 0 && (
            <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredCategories.map((category) => (
                <Combobox.Option
                  key={category.slug}
                  value={category}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-8 pr-4",
                      active
                        ? "bg-primary-blue text-primary-teal"
                        : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {category.name}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 left-0 flex items-center pl-1.5",
                            active ? "text-white" : "text-primary-blue"
                          )}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
      <PrimaryButton
        onClick={assignNewCategory}
        title="Add Category"
      ></PrimaryButton>
    </div>
  );
}

export { AddCategoryStore };
