import { useEffect, useState } from "react"
import { Category } from "../../../types"

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import classNames from "classnames"
import { InformationCircleIcon, MenuAlt4Icon } from "@heroicons/react/outline"
import Skeleton from "react-loading-skeleton"
import toast from "react-hot-toast"
import {
  getAllCategories,
  reorderCategories,
} from "../../../data/categories.data"
import withProtected from "../../../core/hoc/withProtected"
import { Breadcrumb } from "../../../core/components/Breadcrumb"

// a little function to help us with reordering the result
const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const ReOrderCategories = () => {
  const [categories, setCategories] = useState<Category[]>([])

  const [loading, setLoading] = useState(false)

  async function onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items: Category[] = reorder(
      categories,
      result.source.index,
      result.destination.index
    )

    items.forEach((item, index) => {
      item.index = index + 1
    })

    const _categories = items.map((item) => ({
      index: item.index,
      slug: item.slug,
    }))

    setLoading(true)

    const response = await reorderCategories(_categories)

    if (response.status === 200) {
      toast.success(response.data.message)
    }

    setLoading(false)

    setCategories(items)
  }

  useEffect(() => {
    setLoading(true)
    getAllCategories()
      .then((res) => {
        setLoading(false)
        setCategories(res)
      })
      .catch((err) => {})
  }, [])
  return (
    <div className="pt-6 pb-20">
      <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumb
            paths={[
              {
                name: "Categories",
                route: "/categories",
              },
              {
                name: "Re-Order",
                route: `/categories/reorder`,
                asLink: false,
              },
            ]}
          />
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Re-Order Stores
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                You can re-order stores here
              </p>
            </div>
            {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <PrimaryButton
                title="Add Store"
                icon={<PlusIcon className="w-5 h-5" />}
              />
            </div> */}
          </div>

          <div className="p-4 mt-4 rounded-md bg-blue-50">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="w-5 h-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="flex-1 ml-3 md:flex md:justify-between">
                <p className="text-sm text-blue-400">
                  Only top 10 categories will show in web store page
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-8">
            {loading && (
              <div className="space-y-2">
                <div
                  className={classNames(
                    "bg-white",
                    "px-4 py-4 flex w-full items-center rounded-md border"
                  )}
                >
                  <div>
                    <MenuAlt4Icon
                      className={classNames("text-gray-500", "w-6 h-6 mr-4 ")}
                    />
                  </div>
                  <div className="mr-4">
                    <Skeleton className="w-8 h-8 rounded-full "></Skeleton>
                  </div>
                  <div className="flex-shrink-0 w-10 h-10">
                    <Skeleton className="w-10 h-10 rounded-md" />
                  </div>
                  <Skeleton
                    className={classNames("ml-4 min-w-[300px]")}
                  ></Skeleton>
                </div>
                <div
                  className={classNames(
                    "px-4 py-4 flex w-full items-center rounded-md border"
                  )}
                >
                  <div>
                    <MenuAlt4Icon
                      className={classNames("text-gray-500", "w-6 h-6 mr-4 ")}
                    />
                  </div>
                  <div className="mr-4">
                    <Skeleton className="w-8 h-8 rounded-full "></Skeleton>
                  </div>
                  <div className="flex-shrink-0 w-10 h-10">
                    <Skeleton className="w-10 h-10 rounded-md" />
                  </div>
                  <Skeleton
                    className={classNames("ml-4 min-w-[300px]")}
                  ></Skeleton>
                </div>
              </div>
            )}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={classNames(
                      // snapshot.isDraggingOver ? "bg-white" : "bg-white",
                      "space-y-2"
                    )}
                  >
                    {!loading &&
                      categories.map((item, index) => (
                        <Draggable
                          key={item.slug}
                          draggableId={item.slug}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={classNames(
                                snapshot.isDragging
                                  ? "bg-primary-blue-dark"
                                  : "bg-white",
                                "px-4 py-4 flex items-center rounded-md border"
                              )}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                              }}
                            >
                              <div>
                                <MenuAlt4Icon
                                  className={classNames(
                                    snapshot.isDragging
                                      ? "text-primary-teal"
                                      : "text-gray-500",
                                    "w-6 h-6 mr-4 "
                                  )}
                                />
                              </div>
                              <div className="mr-4">
                                <span className="flex items-center justify-center w-8 h-8 rounded-full text-primary-teal bg-primary-blue">
                                  {index + 1}
                                </span>
                              </div>
                              {/* <div className="flex-shrink-0 w-10 h-10">
                                <img
                                  className="w-10 h-10 rounded-md"
                                  src={`${process.env.REACT_APP_STORES_ASSETS}/${item.cover}`}
                                  alt={item.name}
                                />
                              </div> */}
                              <p
                                className={classNames(
                                  snapshot.isDragging ? "text-white" : "",
                                  "ml-4"
                                )}
                              >
                                {item.name}
                              </p>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    </div>
  )
}
export default withProtected(ReOrderCategories);
