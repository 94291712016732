import React, { useEffect } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { Banner } from "../../../types"
import classNames from "classnames"
import BannerHeading from "./BannerHeading"
import { XIcon } from "@heroicons/react/outline"

interface BannerListDragDropProps {
  data?: Banner[]
  loading: boolean

  setBannerSlugs: (slugs: string[]) => void
}

const BannerListDragDrop = ({
                              data,
                              loading,
                              setBannerSlugs,
                            }: BannerListDragDropProps) => {
  const [bannerList, setBannerList] = React.useState<Banner[]>(data || [])
  const onDragEnd = (result: any) => {
    const draggingBanner = result.draggableId
    const destinationIndex = result.destination?.index

    if (destinationIndex !== undefined) {
      const newBannerList = bannerList.filter(
        (banner) => banner.slug !== draggingBanner,
      )
      // insert the dragging banner to the destination index
      newBannerList.splice(destinationIndex, 0, {
        ...bannerList.find((banner) => banner.slug === draggingBanner),
      } as Banner)

      setBannerList(newBannerList)
    }
  }

  useEffect(() => {
    setBannerList(data || [])
  }, [data])

  useEffect(() => {
    if (bannerList && bannerList.length > 0) {
      const bannerSlugs = bannerList.map((banner) => banner.slug)
      setBannerSlugs(bannerSlugs)
    }
  }, [bannerList])

  const removeBannerFromTheList = (bannerSlug: string) => {
    const newBannerList = bannerList.filter(
      (banner) => banner.slug !== bannerSlug,
    )

    setBannerList(newBannerList)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, _snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={classNames(
              // snapshot.isDraggingOver ? "bg-white" : "bg-white",
              "space-y-2",
            )}
          >
            {!loading &&
              bannerList &&
              bannerList.map((singleBanner, index) => (
                <Draggable
                  key={singleBanner.slug}
                  draggableId={singleBanner.slug}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      className={classNames(
                        snapshot.isDragging ? "bg-stone-50" : "bg-white",
                        "flex lg:items-center rounded-md lg:h-18 border relative justify-between",
                      )}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        backgroundImage : `url(${process.env.REACT_APP_STORES_ASSETS}/${singleBanner.tabletThumbnail})`,
                        backgroundRepeat : 'no-repeat'
                      }}
                    >
                      <div className={`flex flex-row`}>
                        <img
                          className="h-14 w-auto "
                          src={`${process.env.REACT_APP_STORES_ASSETS}/${singleBanner.tabletThumbnail}`}
                          alt=""
                        />
                        <h3 className={`my-auto pl-3`}>
                          {singleBanner.title}
                        </h3>
                      </div>

                      <div
                        onClick={() =>
                          removeBannerFromTheList(singleBanner.slug)
                        }
                      >
                        <span
                          className={`text-primary-blue text-sm font-medium mr-5 cursor-pointer text-primary-red`}
                        >
                          Remove
                        </span>
                      </div>
                    </div>

                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default BannerListDragDrop
