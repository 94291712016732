import React, { useEffect, useState } from "react"
import { Switch } from "@headlessui/react"
import { ExclamationCircleIcon, XIcon } from "@heroicons/react/outline"
import { useForm, UseFormSetValue } from "react-hook-form"
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"

import classNames from "classnames"
import toast from "react-hot-toast"

import {
  createKeyword,
  createKeywordStores,
  createStore,
  getAllKeywords,
  getStoresByCategory,
  removeOldNewArrivalStores,
  slugifyWord,
  uploadImage,
} from "../../../data/stores.data"
import {
  assignCategory,
  reorderCategoryStores,
} from "../../../data/categories.data"
import { Category, Location, UploadItem } from "../../../types"

import PrimaryButton from "../../../core/components/Button"
import Select from "../../../core/components/Select"
import CoverImageDropZone from "../components/CoverImageDropZone"
import LogoDropZone from "../components/LogoDropZone"
import { AddCategoryStore } from "../components/AddCategoryStore"
import { Breadcrumb } from "../../../core/components/Breadcrumb"
import withProtected from "../../../core/hoc/withProtected"
import Keywords from "../components/Keywords"
import StoreLocationCard from "../components/StoreLocationCard/StoreLocationCard"
import { AddSubStore } from "../../../data/location_data"

interface FieldValues {
  name: string
  url: string
  cover: string
  logo: string
  slug: string
  alterText: string
  description: string
  promotionLabel: string
  promotionDescription: string
  merchantID: string,
  cashback: string
}

interface subStore {
  id: string
  nameOfPlace: string
  address: string
  addressUrl: string
}

const availability = [
  { id: 1, label: "In-Store only", value: "INS" },
  { id: 2, label: "Online only", value: "ONL" },
  { id: 3, label: "In-Store & Online", value: "BTH" },
  { id: 4, label: "None", value: null },
]

const AddStore = () => {
  const [isLogoLight, setIsLogoLight] = useState(true)
  const [storeAvailability, setStoreAvailability] = useState<any>(
    availability[3],
  )
  const [logo, setLogo] = useState<File | null>(null)
  const [cover, setCover] = useState<File | null>(null)
  const [coverWithLogo, setCoverWithLogo] = useState<File | null>(null)
  const [addedCategories, setAddedCategories] = useState<Category[]>([])
  const [name, setName] = useState<string | null>(null)

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm<FieldValues>()

  // watch name for default slug
  const nameWatch = watch("name")
  const slugWatch = watch("slug")

  function getExtension(path: string) {
    const segments = path.split(".")
    const lastIndex = segments.length - 1
    return segments[lastIndex]
  }

  const removeCategory = async (slug: string) => {
    const index = addedCategories.findIndex((item) => item.slug === slug)
    if (index > -1) {
      addedCategories.splice(index, 1)
    }

    setAddedCategories([...addedCategories])
  }

  // submit new store
  const onSubmit = async (data: FieldValues) => {
    if (!logo) {
      return toast.error("Logo must be required!")
    }

    if (!cover) {
      return toast.error("Cover must be required!")
    }

    if (!coverWithLogo) {
      return toast.error("Cover image with logo must be required!")
    }

    // if (storeAvailability.id === 1 || storeAvailability.id === 3) {
    //   if (locationStores.length === 0) {
    //     return toast.error("Please add at least one Location!")
    //   }
    // }

    if (isNaN(parseFloat(data.cashback))) {
      return toast.error("Cashback must be a number!")
    }

    if (data.promotionDescription) {
      if (!data.promotionLabel) {
        return toast.error("Please add promotion label!")
      }
    }

    // upload cover & logo
    const uploadList: UploadItem[] = [
      {
        key: `stores/${data.slug}/logo-${Date.now()}.${getExtension(
          logo.name,
        )}`,
        file: logo,
      },

      {
        key: `stores/${data.slug}/cover-${Date.now()}.${getExtension(
          cover.name,
        )}`,
        file: cover,
      },
      {
        key: `stores/${data.slug}/cover-with-logo-${Date.now()}.${getExtension(
          coverWithLogo.name,
        )}`,
        file: coverWithLogo,
      },
    ]

    setLoading(true)
    try {
      // upload requests list
      const uploadRequests: Promise<void>[] = uploadList.map((e) =>
        uploadImage(e),
      )

      // uploading all documents
      await Promise.all(uploadRequests)

      const body: { [key: string]: any } = {
        name: data.name,
        url: data.url,
        logo: uploadList[0].key,
        cover: uploadList[1].key,
        coverWithLogo: uploadList[2].key,
        alterText: data.alterText,
        isLogoLight: isLogoLight,
        storeAvailability: storeAvailability.label,
        slug: data.slug,
        storeType: storeAvailability.value,
        cashback: data.cashback,
        merchantID: data.merchantID,
      }

      if (data.promotionLabel) {
        body.promotionLabel = data.promotionLabel
      }

      if (data.promotionDescription) {
        body.promotionDescription = data.promotionDescription
      }

      if (data.description) {
        body.description = data.description
      }

      const response = await createStore(body)

      if (response.data.success) {
        toast.success(response.data.message)

        if (addedCategories.length > 0) {
          const promises = addedCategories.map((category) =>
            assignCategory(data.slug, category.slug),
          )

          if (addedCategories.some((item) => item.slug === "new-arrivals")) {
            const categoryStores = await getStoresByCategory("new-arrivals")

            if (categoryStores?.success) {
              let ordered = categoryStores.data?.result.stores.map((item) => ({
                index: item.index,
                slug: item.slug,
              }))

              ordered = ordered
                ?.filter((i) => i.slug !== data.slug!)
                .map((j) => ({ index: j.index + 1, slug: j.slug }))

              // structure new ordered items
              const newOrdered = [{ index: 1, slug: data.slug! }, ...ordered!]

              // reorder categoryStores with new order
              const resultReOrder = await reorderCategoryStores(
                "new-arrivals",
                newOrdered,
              )

              if (resultReOrder.data.success) {
                toast.success("Successfully Re-Ordered!")
              }
            }

            // remove old new arrivals stores
            await removeOldNewArrivalStores()
              .then((res) => {
                toast.success(res.data.message)
              })
              .catch((err) => {
                toast.error(err.message)
              })
          }

          await Promise.all(promises)
        }

        if (selectedKeywords.length > 0) {
          setLoading(true)

          const uniqueKeywords = selectedKeywords.filter(
            (item, pos, self) => self.indexOf(item) == pos,
          )

          getAllKeywords()
            .then(async (response: any[]) => {
              let keyword_promises: PromiseLike<any>[] = []

              const keywordArray = response.map((item) =>
                slugifyWord(item.slug),
              )

              const notFoundKeywords = uniqueKeywords
                .filter(
                  (keyword) => !keywordArray.includes(slugifyWord(keyword)),
                )
                .map((keyword) => slugifyWord(keyword))

              console.log(notFoundKeywords)

              if (notFoundKeywords.length > 0) {
                keyword_promises = notFoundKeywords.map((keyword) =>
                  createKeyword(slugifyWord(keyword), slugifyWord(keyword)),
                )
              }

              await Promise.all(keyword_promises)
            })
            .then(() => {
              const keyword_store_promises = uniqueKeywords.map((keyword) =>
                createKeywordStores(
                  body.slug,
                  slugifyWord(keyword),
                  slugifyWord(keyword),
                ),
              )

              Promise.all(keyword_store_promises).catch((err) => {
                toast.error(err.message)
              })
            })
        }

        if (locationStores.length > 0) {
          setLoading(true)

          const location_store_promises = locationStores.map(
            async (location: Location) =>
              await AddSubStore(
                location.nameOfPlace,
                location.slug,
                location.addressUrl,
                location.address,
                location.storeSlug,
              ),
          )

          Promise.all(location_store_promises).catch((err) => {
            toast.error(err.message)
          })
        }

        setLoading(false)

        navigate("/stores")
      } else {
        toast.error(response.data.message ?? "Error occurred")
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error.message ?? "Error occurred")
    }
  }

  useEffect(() => {
    setName(nameWatch)
  }, [nameWatch])

  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([])
  const handleKeywordsSelection = (keywords: string[]) => {
    setSelectedKeywords(keywords)
  }

  interface keywordObjData {
    url: string
    slug: string
  }

  const [keywordData, setKeywordData] = useState<keywordObjData>({
    url: "",
    slug: "",
  })

  const [subStores, setSubStores] = useState<subStore[]>([])
  const [locationStores, setLocationStores] = useState<Location[]>([])

  useEffect(() => {
    const backendSendingLocations = setupLocationArray()

    setLocationStores(backendSendingLocations)
  }, [subStores])

  const setupLocationArray = (): Location[] => {
    const LocationObj: Location[] = []

    subStores.forEach((item: subStore) => {
      LocationObj.push({
        nameOfPlace: item.nameOfPlace,
        slug: item.id,
        addressUrl: item.addressUrl,
        address: item.address,
        storeSlug: slugWatch,
      })
    })

    return LocationObj
  }

  interface GenerateSlugBtnsProps {
    name: string;
    setValue: UseFormSetValue<FieldValues>;
  }

  const generateNoSpacesSlug = (name: string): string => {
    const sanitizedName = name.replace(/&/g, "and").trim()
    return sanitizedName.replace(/[^a-z0-9]/g, "").toLowerCase()
  }

  const generateHyphenatedSlug = (name: string): string => {
    const sanitizedName = name.replace(/&/g, "and").trim()
    const slug = sanitizedName
      .replace(/[^a-z0-9\s]/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase()
    return slug.replace(/^-|-$/g, "")
  }

  const generateSlugBtns = ({ name, setValue }: GenerateSlugBtnsProps) => {
    const noSpacesSlug = generateNoSpacesSlug(name)
    const hyphenatedSlug = generateHyphenatedSlug(name)

    return (
      <div className="mt-3">
        <div className="flex space-x-2">
          <span className="text-sm relative top-1">Suggestion: </span>
          <button
            type="button"
            onClick={() => setValue("slug", noSpacesSlug)}
            className="px-3 py-1 text-sm font-medium rounded-full bg-primary-teal text-primary-blue hover:-translate-y-[0.8px] hover:shadow-md active:translate-y-[0.8px] active:shadow-md"
          >
            {noSpacesSlug}
          </button>
          {name.includes(" ") && (
            <button
              type="button"
              onClick={() => setValue("slug", hyphenatedSlug)}
              className="px-3 py-1 text-sm font-medium rounded-full bg-primary-teal text-primary-blue hover:-translate-y-[0.8px] hover:shadow-md active:translate-y-[0.8px] active:shadow-md"
            >
              {hyphenatedSlug}
            </button>
          )}
        </div>
      </div>
    )
  }


  useEffect(() => {
    if (nameWatch) {
      const hyphenatedSlug = generateHyphenatedSlug(nameWatch)
      setValue("slug", hyphenatedSlug)
    } else {
      setValue("slug", "")
    }
  }, [nameWatch])


  return (
    <>
      <div className="pt-6 pb-20">
        <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <Breadcrumb
              paths={[
                {
                  name: "Stores",
                  route: "/stores",
                },
                {
                  name: "Add Store",
                  route: "/stores/add",
                  asLink: false,
                },
              ]}
            />
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">
                  Add Store
                </h1>
                <p className="mt-2 text-sm text-gray-700">
                  You can add store here
                </p>
              </div>
            </div>

            <div className="flex flex-col mt-8">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-8 divide-y divide-gray-200"
              >
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div>
                    <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                      <div
                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="username"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Store Name
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="relative flex max-w-lg rounded-md shadow-sm">
                            <input
                              disabled={loading}
                              type="text"
                              {...register("name", {
                                required: "Name cannot be empty",
                              })}
                              autoComplete="name"
                              className={classNames(
                                errors && errors.name
                                  ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                                  : "focus:ring-primary-teal focus:border-primary-teal",
                                "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm",
                              )}
                            />
                            {errors && errors.name && (
                              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                <ExclamationCircleIcon
                                  className="w-5 h-5 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>

                          {errors && errors.name && (
                            <div className="mt-2 text-sm text-red-600">
                              {errors.name.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="username"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Merchant ID
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="relative flex max-w-lg rounded-md shadow-sm">
                            <input
                              disabled={loading}
                              type="text"
                              {...register("merchantID", {
                                required: "Merchant ID cannot be empty",
                              })}
                              autoComplete="merchantID"
                              className={classNames(
                                errors && errors.merchantID
                                  ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                                  : "focus:ring-primary-teal focus:border-primary-teal",
                                "flex-1 block w-1/2 pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm",
                              )}
                            />
                            {errors && errors.merchantID && (
                              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                <ExclamationCircleIcon
                                  className="w-5 h-5 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>

                          {errors && errors.merchantID && (
                            <div className="mt-2 text-sm text-red-600">
                              {errors.merchantID.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="username"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Cashback
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="relative flex max-w-lg rounded-md shadow-sm">
                            <input
                              disabled={loading}
                              type="text"
                              {...register("cashback", {
                                required: "Cashback cannot be empty",
                              })}
                              autoComplete="cashback"
                              className={classNames(
                                errors && errors.cashback
                                  ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                                  : "focus:ring-primary-teal focus:border-primary-teal",
                                "flex-1 block pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm w-1/2",
                              )}
                            />
                            {errors && errors.cashback && (
                              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                <ExclamationCircleIcon
                                  className="w-5 h-5 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>

                          {errors && errors.cashback && (
                            <div className="mt-2 text-sm text-red-600">
                              {errors.cashback.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="slug"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Slug
                          <span className={`text-xs text-gray-400 pl-2`}>
                            (Disabled Type, Auto Generated)
                          </span>
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="relative flex max-w-lg rounded-md shadow-sm">
                            <input
                              disabled={true}
                              type="text"
                              {...register("slug", {
                                required: "Slug cannot be empty",
                              })}
                              autoComplete="slug"
                              className={classNames(
                                errors && errors.slug
                                  ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                                  : "focus:ring-primary-teal focus:border-primary-teal",
                                "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm",
                              )}
                            />
                            {errors && errors.slug && (
                              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                <ExclamationCircleIcon
                                  className="w-5 h-5 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>
                          {errors && errors.slug && (
                            <div className="mt-2 text-sm text-red-600">
                              {errors.slug.message}
                            </div>
                          )}
                          {name && generateHyphenatedSlug(name) && <>{generateSlugBtns({ name, setValue })}</>}
                        </div>
                      </div>
                      <div
                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="alterText"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Alter Text
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="relative flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              {...register("alterText")}
                              disabled={loading}
                              autoComplete="alterText"
                              className={classNames(
                                errors && errors.alterText
                                  ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                                  : "focus:ring-primary-teal focus:border-primary-teal",
                                "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm",
                              )}
                            />
                            {errors && errors.alterText && (
                              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                <ExclamationCircleIcon
                                  className="w-5 h-5 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>
                          {errors && errors.alterText && (
                            <div className="mt-2 text-sm text-red-600">
                              {errors.alterText.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="storeUrl"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Store URL
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="relative flex max-w-lg rounded-md shadow-sm">
                            <input
                              disabled={loading}
                              type="text"
                              {...register("url", {
                                onBlur: (e) => {
                                  setKeywordData({
                                    ...keywordData,
                                    url: slugifyWord(e.target.value),
                                  })
                                },
                              })}
                              autoComplete="storeUrl"
                              className={classNames(
                                errors && errors.url
                                  ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                                  : "focus:ring-primary-teal focus:border-primary-teal",
                                "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm",
                              )}
                            />
                            {errors && errors.url && (
                              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                <ExclamationCircleIcon
                                  className="w-5 h-5 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>
                          {errors && errors.url && (
                            <div className="mt-2 text-sm text-red-600">
                              {errors.url.message}
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="cover-photo"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Logo
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <LogoDropZone
                            onDrop={(file) => {
                              setLogo(file)
                            }}
                          />
                        </div>
                      </div>

                      <div
                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="cover-photo"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Cover Image
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <CoverImageDropZone
                            onDrop={(file) => {
                              setCover(file)
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="cover-photo"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Cover with logo
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <CoverImageDropZone
                            onDrop={(file) => {
                              setCoverWithLogo(file)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="slug"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Logo Light
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="flex items-center space-x-4">
                        <p>Dark</p>
                        <Switch
                          disabled={loading}
                          checked={isLogoLight}
                          onChange={setIsLogoLight}
                          className={classNames(
                            isLogoLight ? "bg-primary-teal" : "bg-gray-200",
                            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal",
                          )}
                        >
                          <span className="sr-only">Use setting</span>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              isLogoLight ? "translate-x-5" : "translate-x-0",
                              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
                            )}
                          />
                        </Switch>
                        <p>Light</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="slug"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Store Availability
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="relative flex max-w-lg rounded-md shadow-sm">
                        <Select
                          defaultValue={availability[3]}
                          onChange={(value) => {
                            setStoreAvailability(value)
                          }}
                          options={availability}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="promotionLabel"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Promotion Label
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="relative flex max-w-lg rounded-md shadow-sm">
                        <input
                          disabled={loading}
                          type="text"
                          {...register("promotionLabel", {
                            maxLength: 15,
                          })}
                          autoComplete="promotionLabel"
                          placeholder="Some words about promotion"
                          className={classNames(
                            errors && errors.promotionLabel
                              ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                              : "focus:ring-primary-teal focus:border-primary-teal",
                            "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm",
                          )}
                        />
                        {errors && errors.promotionLabel && (
                          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <ExclamationCircleIcon
                              className="w-5 h-5 text-red-500"
                              aria-hidden="true"
                            />
                          </div>
                        )}
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        This will be displayed on Bottom pill (Max 15
                        characters)
                      </p>
                      {errors && errors.promotionLabel && (
                        <div className="mt-2 text-sm text-red-600">
                          {errors.promotionLabel.message}
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="promotionLabel"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Promotion Description (Optional)
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="relative flex max-w-lg rounded-md shadow-sm">
                        <input
                          disabled={loading}
                          type="text"
                          {...register("promotionDescription")}
                          autoComplete="promotionLabel"
                          placeholder="ex: 20% OFF"
                          className={classNames(
                            errors && errors.promotionDescription
                              ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                              : "focus:ring-primary-teal focus:border-primary-teal",
                            "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm",
                          )}
                        />
                        {errors && errors.promotionDescription && (
                          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <ExclamationCircleIcon
                              className="w-5 h-5 text-red-500"
                              aria-hidden="true"
                            />
                          </div>
                        )}
                      </div>

                      {errors && errors.promotionDescription && (
                        <div className="mt-2 text-sm text-red-600">
                          {errors.promotionDescription.message}
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Description
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2 relative flex flex-col">
                      <div className={`relative w-full max-w-lg `}>
                        <textarea
                          disabled={loading}
                          {...register("description", {
                            maxLength: 200,
                            required: false,
                          })}
                          id="description"
                          name="description"
                          rows={3}
                          className={classNames(
                            errors && errors.description
                              ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                              : "focus:ring-primary-teal focus:border-primary-teal",
                            `flex-1 block w-full max-w-lg pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm`,
                          )}
                          defaultValue={""}
                        />
                        {errors && errors.description && (
                          <div className="absolute h-full  top-0 right-0 flex items-center pr-3 pointer-events-none">
                            <ExclamationCircleIcon
                              className="w-5 h-5 text-red-500"
                              aria-hidden="true"
                            />
                          </div>
                        )}
                      </div>
                      <div className={`flex justify-between w-full max-w-lg`}>
                        <p className="mt-2 text-sm text-gray-500">
                          Write a few sentences about store. (Max 200
                          characters)
                        </p>
                        {watch("description")?.length > 0 && (
                          <span
                            className={`text-sm mt-2 ${
                              watch("description")?.length > 200 &&
                              "text-red-500"
                            }`}
                          >
                            {watch("description")?.length || 0}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/*<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">*/}
                  {/*  <label*/}
                  {/*    htmlFor="buttonText"*/}
                  {/*    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"*/}
                  {/*  >*/}
                  {/*    Branches*/}
                  {/*  </label>*/}
                  {/*  <div className="mt-1 sm:mt-0 sm:col-span-2">*/}
                  {/*    <div className="w-full rounded-md sm:max-w-lg">*/}
                  {/*      <StoreLocationCard setSubStores={setSubStores} />*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div>
                    <div
                      className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Categories
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <AddCategoryStore
                          onAddCategory={(category) => {
                            if (
                              addedCategories.some(
                                (item) => item.slug === category.slug,
                              )
                            ) {
                              return toast.error("Category added already!")
                            }
                            setAddedCategories([...addedCategories, category])
                          }}
                        />

                        <div className="flex flex-wrap w-full pt-4 pb-4 space-x-2 space-y-2">
                          {addedCategories.map((item) => {
                            return (
                              <motion.div
                                initial={{
                                  opacity: 0,
                                  scale: 0.5,
                                }}
                                animate={{
                                  opacity: 1,
                                  scale: 1,
                                }}
                                exit={{
                                  opacity: 0,
                                  scale: 0.5,
                                }}
                                key={item.slug}
                                className="flex items-center px-4 py-3 space-x-4 rounded-full bg-primary-blue "
                              >
                                <div className="text-sm text-white">
                                  {item.name}
                                </div>
                                <button
                                  type="button"
                                  onClick={() => removeCategory(item.slug)}
                                  className="flex items-center justify-center w-8 h-8 bg-red-500 rounded-full min-w-8"
                                >
                                  <XIcon className="w-6 h-6 text-white min-w-6" />
                                </button>
                              </motion.div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`hidden`}>
                    <div
                      className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                      <label
                        htmlFor="keywords"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Keywords (optional)
                      </label>
                      <Keywords
                        submittable={false}
                        keywordData={keywordData}
                        exportSelectedKeywordsArray={handleKeywordsSelection}
                      />
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end space-x-3">
                    <button
                      disabled={loading}
                      type="button"
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
                    >
                      Cancel
                    </button>
                    <PrimaryButton
                      disabled={loading}
                      loading={loading}
                      type="submit"
                      title={"Submit"}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default withProtected(AddStore)
